<template>
  <tree-transfer
    :title="title"
    :from_data="allMenuList"
    :to_data="roleMenuList"
    :defaultProps="{ label: 'title', children: 'subs' }"
    pid="parentId"
    height="250px"
    @add-btn="handleChange"
    @remove-btn="handleChange"
    filter
    openAll
  >
  </tree-transfer>
</template>

<script>
  import { getAllMenuList, getMenuList, bindMenu } from 'api/account/role';
  import treeTransfer from 'el-tree-transfer';
  export default {
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        title: ['可分配菜单', '已分配'],
        roleMenuList: [],
        allMenuList: [],
      };
    },
    components: {
      treeTransfer,
    },
    mounted() {
      this.filterMenu();
    },
    methods: {
      async getRoleMenuList(role) {
        const response = await getMenuList(role);
        if (response.code === 200) {
          this.roleMenuList = response.result;
        }
      },
      async getAllMenuList() {
        const response = await getAllMenuList();
        if (response.code === 200) {
          this.allMenuList = response.result;
        }
      },
      handleChange(fromData, toData) {
        const cacheData = fromData;

        const menuArr = this.formatObjectArray(toData);
        bindMenu(this.id, menuArr).then((response) => {
          if (response.code !== 200) {
            fromData.push(...cacheData);
          }
        });
      },
      formatObjectArray(array) {
        return array.reduce((prev, cur) => {
          prev.push(cur.id);
          if (Array.isArray(cur.subs)) {
            return prev.concat(this.formatObjectArray(cur.subs));
          }
          return prev;
        }, []);
      },
      filterMenu() {
        const allPromise = Promise.all([this.getRoleMenuList(this.id), this.getAllMenuList()]);
        allPromise.then(() => {
          const map = new Map();
          for (const item of this.roleMenuList) {
            let tempArr = [];
            if (item.subs && Array.isArray(item.subs)) {
              tempArr = item.subs.map((item) => item.id);
            }
            map.set(item.id, tempArr);
          }
          this.allMenuList = this.allMenuList.reduce((prev, cur) => {
            const filterArr = map.get(cur.id);
            if (filterArr && filterArr.length === 0) return prev;
            else if (filterArr) {
              const subs = [];
              for (const item of cur.subs) {
                if (!filterArr.includes(item.id)) {
                  subs.push(item);
                }
              }
              if (subs.length > 0) {
                cur.subs = subs;
                prev.push(cur);
              }
            } else {
              prev.push(cur);
            }

            return prev;
          }, []);
        });
      },
    },
  };
</script>

<style></style>
