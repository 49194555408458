import request from 'utils/request';

const api = {
  ROLE_LIST: '/api/role/list',
  ROLE_MENU_LIST: '/api/role/{roleId}/menu',
  ROLE_ALL_MENU_LIST: '/api/role/all/menu',
  ROLE_ADD: '/api/role/add',
  ROLE_DELETE: '/api/role/delete',
  ROLE_MENU_UPDATE: '/api/role/{roleId}/menu/update',
};
/**
 * 查询用户列表
 * @param {*} query {page:int,pageSize:int}
 */
export function getRoleList(query) {
  return request({
    url: api.ROLE_LIST,
    method: 'get',
    params: query,
  });
}
/**
 * 获取角色对应菜单列表
 * @param {*} roleId 角色id
 */
export function getMenuList(roleId) {
  const url = api.ROLE_MENU_LIST.replace('{roleId}', roleId);
  return request({
    url,
    method: 'get',
  });
}
/**
 * 获取菜单列表
 *
 */
export function getAllMenuList() {
  const url = api.ROLE_ALL_MENU_LIST;
  return request({
    url,
    method: 'get',
  });
}
/**
 * 新增角色
 * @param {*} data {roleName:string,roleDesc:string}
 */
export function insertRole(data) {
  return request({
    url: api.ROLE_ADD,
    method: 'post',
    data,
  });
}

/**
 * 删除角色
 * @param {*} roleId 角色id
 */
export function deleteRole(roleId) {
  return request({
    url: api.ROLE_DELETE,
    method: 'delete',
    data: {
      roleId,
    },
  });
}
/**
 * 角色绑定菜单
 * @param {*} roleId 角色id
 * @param {*} menuIds 菜单id（多角色以','相隔）
 */
export function bindMenu(roleId, menuIds) {
  const url = api.ROLE_MENU_UPDATE.replace('{roleId}', roleId);
  return request({
    url,
    method: 'put',
    data: {
      menuIds,
    },
  });
}
