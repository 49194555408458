<template>
  <div class="roleRightsSetting">
    <div class="title">
      <h2>Role Setting</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :inline="true" :model="role" label-width="100px">
              <el-form-item label="Role Name">
                <el-input
                  v-model="role.roleName"
                  style="width:160px;"
                  placeholder="role name here ..."
                ></el-input>
              </el-form-item>
              <el-form-item label="Role Description" label-width="120px">
                <el-input
                  v-model="role.roleDesc"
                  style="width:160px;"
                  placeholder="role desc here ..."
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="createClick">Create Role</el-button>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              style="width:100%;"
              height="70vmin"
              stripe
              border
              :data="roleList"
              highlight-current-row
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <role-menu :id="props.row.id"></role-menu>
                </template>
              </el-table-column>
              <el-table-column label="Role ID" prop="id" align="center"></el-table-column>
              <el-table-column label="Role Name" prop="roleName" align="center"></el-table-column>
              <el-table-column
                label="Role Description"
                prop="roleDesc"
                align="center"
              ></el-table-column>
              <el-table-column label="Creator" prop="createBy" align="center"></el-table-column>
              <el-table-column
                label="Create Time"
                prop="createTime"
                align="center"
              ></el-table-column>
              <el-table-column label="Action" align="center" min-width="50">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" @click="deleteClick(scope.row)"
                    >Delete</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
            <!-- 删除提示框 -->
            <el-dialog
              title="Delete"
              :visible.sync="delVisible"
              width="300px"
              center
              append-to-body
            >
              <div class="del-dialog-cnt">Confirm to delete ?</div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">cancel</el-button>
                <el-button type="primary" @click="delRow">confirm</el-button>
              </span>
            </el-dialog>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { getRoleList, insertRole, deleteRole } from 'api/account/role';
  import RoleMenu from './components/RoleMenu';
  import Pagination from '@/components/pagination';
  import { myMixin } from '@/mixins/mixins.js';

  const defaultMenu = {
    index: '',
    title: '',
    icon: '',
    weight: '',
    parentId: '',
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      RoleMenu,
    },
    data() {
      return {
        toData: [],
        fromData: [],
        delVisible: false,
        role: {
          roleName: null,
          roleDesc: null,
        },
        title: ['可分配菜单', '已分配'],
        addmenus: Object.assign({}, defaultMenu),
        listLoading: false,
        delValue: null,
        roleList: [],
        roleMenuList: [],
        menuList: [],
        allRoles: [],
        rules: {
          title: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
        },
        pageSize: 10,
        currentPage: 1,
        total: 0,
      };
    },
    mounted() {
      this.getDataList();
    },
    methods: {
      delMenuClick() {
        this.setNull(this.delValue);
        this.delMenuVisible = true;
      },
      getDataList() {
        let param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getRoleList(param).then((response) => {
          if (response.code == 200) {
            this.roleList = response.result;
            this.total = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      createClick() {
        if (this.role.roleName == null) {
          this.$message.error('角色不能为空');
          return true;
        }
        insertRole({ ...this.role }).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getDataList();
          } else {
            this.$message.error('Add Error' + response.message);
          }
        });
      },
      deleteClick(row) {
        this.roleId = row.id;
        this.delVisible = true;
      },
      delRow() {
        deleteRole(this.roleId).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getRoleMenuList();
          } else {
            this.$message.error('Error' + response.status.msg);
          }
        });
        this.delVisible = false;
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.searchListClick();
      },
    },
  };
</script>
<style lang="scss">
  .el-tree-node__label {
    font-size: 14px;
    margin-left: 4px !important;
  }
</style>
<style lang="scss" scoped></style>
